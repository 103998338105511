<template>
  <div class="commentRule">
    <div class="commentRule-t">
      <div class="title">议什么？</div>
      <div class="main">
        <div class="p">宣传党的路线、方针、政策</div>
        <div class="p">商议社区重要工作安排</div>
        <div class="p">商议选民群众普遍关心的热点难点问题</div>
        <div class="p">商议社区民生实事项目相关事项</div>
        <div class="p">手机对社区建设的意见和建议</div>
      </div>
    </div>
    <div class="commentRule-c">
      <div class="title">谁来议？</div>
      <div class="main">
        <div class="p">
          由议事代表牵头，召集相关社区党员、选民群众、网格员等参加
        </div>
      </div>
    </div>
    <div class="commentRule-b">
      <div class="title">怎么议？</div>
      <div class="main">
        <div class="p">
          代表议事廊一般每两个月召集一次议事，具体日期在
          社区宣传窗及乐享井亭”APP中通知
        </div>
        <div class="p">
          牵头议事代表根据民情民意收集情况确定议事主题，
          邀请相关议事人员参与，选民群众也可要求加入议事
        </div>
        <div class="p">
          议事过程和意见建议制定专人记录汇总，属于议事范
          围的由议事代表推动解决或提交镇人大协调联系
        </div>
        <div class="p">
          意见建议收集落实情况通过一定的形式进行公布，并 保证议事公开、透明
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
export default {
  name: "commentRule",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.commentRule {
  min-height: 100vh;
  padding: 20px 32px;
  .commentRule-t,
  .commentRule-c,
  .commentRule-b {
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 16px 26px;
    .title {
      font-weight: 700;
      font-size: 32px;
      color: #1a1c34;
      line-height: 44px;
      margin-bottom: 18px;
    }
    .p {
      font-weight: 400;
      padding-left: 52px;
      font-size: 26px;
      color: #1a1c34;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }
  .commentRule-t,
  .commentRule-b {
    .p {
      position: relative;
      &::before {
        content: "1";
        width: 28px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        margin: auto;
        height: 28px;
        background: #fb8133;
        border: 2px solid #2d0206;
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        color: #ffffff;
      }
      &:nth-child(2) {
        &::before {
          content: "2";
        }
      }
      &:nth-child(3) {
        &::before {
          content: "3";
        }
      }
      &:nth-child(4) {
        &::before {
          content: "4";
        }
      }
      &:nth-child(5) {
        &::before {
          content: "5";
        }
      }
    }
  }
  .commentRule-b {
    .p {
      position: relative;
      &::before {
        top: 4px;
        margin: 0;
      }
    }
  }
  .commentRule-c {
    .p {
      padding: 0;
    }
  }
}
</style>
